import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/templates/default-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p>{`Fandomnesia is a Pop Culture Media that presents the latest information and interesting discussions about Film, Otaku, and K-Pop and K-Drama.`}</p>
    <p>{`Along with the growing interest of the millennial generation and Gen Z in Series, Film, and Music, Fandomnesia is here to present the latest and interesting content every day regarding these interests.`}</p>
    <p>{`Interesting content ranging from Review Discussions, Latest Info, Fun Facts, Recommendations and others Fandomnesia presents to fans of various series as a medium of information as well as entertainment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      